
.fadeSlideFrame {
    position: relative;
    width: 40%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;    
}

.fadeSlide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    animation: fadeInOut 24s infinite;
    
}

.fadeSlide:nth-child(2) {
    animation-delay: 8s;
}

.fadeSlide:nth-child(3) {
    animation-delay: 16s;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }    
}