@import url(https://fonts.googleapis.com/css?family=Nunito);
html, body {
    scrollbar-width: none;
    overflow-x: hidden;
}

.header-container {
    text-align: center;
    background-image: url("/header.jpg");
    height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

h1 {
    font-weight: bold;
    font-style: italic;
    font-family: 'Kalam', cursive;
    font-size: 60px;
    padding: 60px;
}

@media screen and (max-width: 1024px) {
    .header-container {
        height: 300px;
    }
}

@media screen and (max-width: 480px) {

.header-container {
        height: 100px;
}

h1 {
        font-size: 24px;
        padding: 30px;
    }

::-webkit-scrollbar {
        width: 0px;
    }
}

/* ----------------------- */

.triplog {
    width: 60%;
    text-align: left;
    line-height: 2em;
    font-size: 24px;
    margin: 0 auto;
}

.triplog img {
    width: 100%;
}

.triplog h3 {
    text-align: center;
    border-bottom: 1px solid gray;
    padding-bottom: 30px;
    font-family: 'Architects Daughter', cursive;
    font-size: 40px;
}

.triplog-banner {
    font-family: 'Architects Daughter', cursive;
}

.triplog-banner img:hover {
    cursor: pointer;
}

.triplog-banner img {
    border-radius: 8px;
}

p {
    text-indent: 10%;
}

.portage-sign {
    display: flex;
}

.portage-sign-text {

    width: 40%;
    font-size: 20px;
    font-style: italic;
    float: left;
    margin-top: 25px;
}

.portage-sign img {
    max-width: 80%; 
}

.image-text {
    color: gray;
    text-align: right;
    font-size: 18px;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    padding: -40;
}

.image-text p {
    padding: -40px;
    margin-top: -20px;
}

@media screen and (max-width: 1600px) {
    .triplog {
        width: 80%;
        font-size: 22px;
    }
}

@media screen and (max-width: 1366px) {

    .triplog {
        width: 80%;
        font-size: 20px;
    }

    .image-text p {
        padding: -20px;
        margin-top: -5px;
    }

    .portage-sign {
        justify-content: center;
        display: inline-block;
        position: relative;  
    }

    .portage-sign-text {
        width: 90%;
    }

    .triplog-banner img {
        height: 200px;
        width: 96%;
    }

}

@media screen and (max-width: 640px) {

    .triplog {
        width: 90%;
        font-size: 16px;
    }
    
    .triplog h3 {
        font-size: 20px;
    }

    .triplog-list h3 {
        padding-bottom: 0px;
    }

    .triplog-banner img {
        height: 100px;
        width: 96%;
    }
    
    .image-text {
        font-size: 16px;
    }

    .image-text img {
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        margin-top: 3px;
    }

    .portage-sign {
        align-items: center;
        display: inline-block;
        position: relative;   
    }

    .portage-sign-text {
        width: 90%;
    }

}

#rail-hike {

    width: 50%;
    float: left;
    padding-right: 40px;
}

#pancakes-cooking {
    width: 45%;
    float: left;
    padding-top: 10px;
}

#breakfast-ready {
    float: right;
    width: 50%;
}

#coffee {
    width: 50%;
    float: right;
    padding-bottom: 15px;
}

.breakfast-collage img{
    padding-bottom: 60px;
}

#spider {
    width: 40%;
    height: 30%;
    padding: 10px;
    padding-left: 60px;
    float:right;
}

#cicada {
    width: 50%;
    float: right;
}

.spider-text {
    text-align: right;
}

.end-collage img {
    width: 50%;
    float: left;
}







/* ----------------------------------------------------------------------- */




#late-notice {
    margin-left: 15%;
    width: 70%;
}

.portage2-sign {
    display: flex;
}

.portage2-sign img {
    max-width: 60%; 
    height: 40%;
    margin-right: 10px;
    margin-left: 20%;
}

.moss {
    display: flex;
    position: relative;
    text-align: center;
}

.moss img {
    max-width: 50%;
    max-height: 50%;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.moss p {

    position: relative;
}

.frog {
    display: flex;
    position: relative;
    text-align: center;
}

.frog p {
    position: relative;
}

.frog img {
    max-width: 50%;
    max-height: 50%;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}






/* ----------------------------------------------------------------------- */

.hwy-60-pics {
    width: 100%;
}

.hwy-60-pics img {
    width: 48%;
    height: 50%;
    padding: 5px;
}

.muddy-portage-pic img {
    width: 50%;
    margin-left: 25%;
}

.muddy-portage-pic p {
    color: gray;
    text-align: right;
    font-size: 18px;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.large-fungus {
    
    margin: 0 auto;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: black;
    opacity: 1.0;
    transition: opacity 5s ease-in;
    margin-top: 10%;
}

.large-fungus img {

    width: 50%;
    height: 100%;
    max-height: 650px;
    
}

.close-fungus-btn {

    width: 25px;
    height: 25px;
    text-align: center;
    border: none;
    border-radius: 6px;
    background-color:rgb(255, 255, 255);
    color: rgb(231, 50, 50);
    font-size: 24px;
    position: absolute;
    margin-top: 20px;
    margin-left: 50px;
    text-decoration: none;
    outline: none;
    z-index: 3;
}

.fungus-grid {

    text-align: center;
    align-items: center;
    margin-top: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.fungus-container {

    min-width: 60%;
    min-height: 40%; 
}

.large-fungus img {

    width: 80%;
    height: 80%;
}

.fungus-text {

    margin-top: 20px;
}

@media screen and (max-width: 1024px) {

    .close-fungus-btn {
        margin-left: 15px;
        width: 35px;
        height: 35px;
    }

    .fungus-grid {
        
        margin-top: 10%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
}

@media screen and (max-width: 460px) {

    .close-fungus-btn {
        margin-top: 5px;
        margin-left: 0px;
        width: 20px;
        height: 20px;
    }

    .fungus-grid {

        margin-top: 10%;
        display: grid;
        grid-template-columns: 1fr;
    }
}

.fungus-grid img {

    min-width: 100px;
    min-height: 100px;
    max-width: 240px;
    max-height: 240px;
}

.fungus-item:hover {

    opacity: 0.6;
}

/* Big Trout Lake 2021 Log CSS //////////////////*/ 

.btl-map-container {

    width: 100%;
    display: inline-flex;
}

.map-image {
    width: 50%;
}

.map-text {
    width:46%;
    margin: auto;
    font-size: 1em;
    font-style: italic;
}

.image-frame50 {
    display: inline-flex;
    justify-content: center;
}

.image-frame50 img {
    width: 48%; 
    height: 50%;
    padding: 20px;
}

.img50-right {
    display: inline-flex;
    margin-bottom: 20px;
}

.img50-right img {
    width: 48%;
    height: 50%;
    padding: 10px;
    margin:auto;
}

.img50-right p {
    margin: auto;
    width: 50%;
}

.bear-country-img img:first-of-type {
    width: 30%;
}

.bear-country-img img {
    width: 68%;
    margin-left: 1%;
}

.pagenav {
    justify-content: space-around;
    display: flex;
}

.pagenav a {
    text-decoration: none;
    cursor:pointer;
}

.pagenav a:hover {
    opacity: 0.8;
    font-weight: bolder;
}

.end-text {
    width: 100%;
    text-align: center;
}

.image-frame {
    position: relative;
    display: flex;
    background-color: black;
    max-width: 90vw;
    max-height: 90vh;
    margin-left: 5vw;
    margin-top: 5vh;
}

.image-frame img {
    width: 100%;
    object-fit: contain;
}

.close-imageview-btn {

    position: fixed;
    right: 3%;
    top: 20px;
    color: white;
    font-size: 36px;
    font-weight: bolder;
    cursor: pointer;
    border: 3px solid rgb(89, 89, 89);
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
    text-shadow: #000 0px 0px 1px,   #000 0px 0px 1px,   #000 0px 0px 1px,
             #000 0px 0px 1px,   #000 0px 0px 1px,   #000 0px 0px 1px;
}

.expandable-image {
    cursor: zoom-in;
}

@media screen and (max-width: 1366px) {

    .btl-map-container {
        display: block;
    }
    .map-image {
        width: 100%;
    }
    
    .map-text {
        width:96%;
        margin: auto;
        text-align: center;
    }

    .image-frame50 {
        display:block;
        justify-content: center;
    }

    .image-frame50 img {
        width: 50%; 
        height: 50%;
        padding: 5px;
    }

    .img50-right {
        display: block;
        margin-bottom: 5px;
    }

    .img50-right p {
        margin: auto;
        width: 100%;
    }

    .img50-right img {
        width: 100%;
        height: 100%;
        padding: 20px;
    }
}

@media screen and (max-width: 480px) {

    .map-text {
        width:96%;
        margin: auto;
        font-size: 16px;
        text-align: center;
    }

    .image-frame {
        width: 95vw;
        height: 90vh;
        margin-left: 2.5vw;
        margin-top: 8vh;
    }

    .pagenav {
        margin-top: 5%;
        font-size: 0.8em;
    }
}


/* ----------------------------------------------------- */

html {
  height: 100%;
  scrollbar-width: none;
}

body { 
  margin:0;
  padding:0;
  height: 100%;
  font-family: Nunito;
  text-align: center;
}

.title {
  width: 50%;
  font-size: 24px;
  text-align: center;
  margin:auto;
  padding: 8px;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 5%;
}

.text-container {

    width: 50%;
    font-size: 24px;
    text-align: center;
    margin:auto;
    padding: 8px;
    margin-top: 40px;
    margin-bottom: 100px;
    line-height: 2;
    padding: 5%;
    border-radius: 5px;
    background: rgba(240,230,220, .7);
    box-shadow: 0 0 8px rgba(0, 0, 0, .7);
}

.slide-1 {
    background-image: url("/slide1.jpg");    
    height: 800px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide-2 {
    background-image: url("/slide2.jpg");
    height: 800px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 1366px) {
    .slide-1, .slide-2 {
        height: 600px;
    }
    .text-container {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {

  .text-container {
    width: 80%;
    font-size: 14px;
  }

  .slide img {
    width: 100%;
  }

  li {
    font-size: 16px;
  }

  #google-map {
    width: 90%;
    height: 250px;
  }
  
}

#google-map {

  margin-bottom: 50px;
}

.slide {
  background-color: black;
  width: 100%;
  color: white;
  text-align: center;
}

.slide img {
  width: 90%;
  height: auto;  
}

.slide-caption {
  color: white;
  text-align: center;
}

.map-slide {

  margin: 30px;
  margin-top: 60px;
  border:none;
  border-radius: 6px;  
}

.resource-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  gap: 1em;
  padding: 50px;
}

.resource-icons img{

  margin: auto;
  width: 80px;
  height: 80px;
  border: none;
}

h4 {
  margin: 0;
  padding: 0px;
}

h3 {
  margin-top: 5%;
  font-size: 30px;
}

#resource-title {
    margin: 0 auto;
    margin-top: 5%;
    width: 300px;
    border-bottom: 1px solid gray;
}

.map-slide-text {
  margin: 0 auto;
  font-size: 20px;
  text-align: left;
  width: 60%;
}

.map-slide-text a {
    text-decoration: none;
    color: rgb(146, 219, 146);
    font-style: italic;
}

.map-slide-text a:hover {
    color:rgb(94, 158, 66);
}

@media screen and (max-width: 460px) {
    .map-slide-text {
        width: 95%;
      }

      .map-slide-text h2 {
          text-align: center;
      }
}

li a {
  text-decoration: none;
  color: black;
}

/* ---- FOOTER -------------------------------------------------- */


.footer {
    width: 100%;
    height: 70px;
    background-color: rgb(105, 126, 96);
    color: white;
    font-size: 16px;   
}

#copyright-text {

    position: relative;
    top: 30px;
}

@media screen and (max-width: 460px) {

    .footer {
        font-size: 14px;
    }
}

/* --------- PICTURE GALLERY ----------------------------------------------------- */

.gallery-container {
    background-color: white;
    padding-top: 30px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.gallery-grid {
    display: grid;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 50px;
    row-gap: 50px;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    background-attachment: fixed;
}

@media screen and (max-width: 1024px) {

    .gallery-grid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 0px;
        -webkit-column-gap: 0px;
                column-gap: 0px;
        grid-row-gap: 20px;
        row-gap: 20px;
    }   
}

@media screen and (max-width: 500px) {

    .gallery-grid {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        -webkit-column-gap: 0px;
                column-gap: 0px;
        grid-row-gap: 10px;
        row-gap: 10px;
    }   
}

.thumb-image img{
    width: 90%;    
    border-radius: 6px;
    cursor: pointer;
    transition: opacity .15s ease-in-out;
}

.thumb-image img:hover {
    opacity: 0.6;  
}

.page-nav-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.page-link {
    font-size: 2em;
    margin: 12px;
    padding: 6px;
}

.page-link:hover {
    opacity: 0.6;
}

.page-sel {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-radius: 8px;;
}

#prev-page-btn, #next-page-btn {
    font-size: 3em;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin: 20px;
    transition: opacity .15s ease-in-out;
}

@media screen and (max-width: 1024px) {

    #prev-page-btn, #next-page-btn {
        font-size: 3em;
        margin: 10px;
    }    

    .page-link {
        font-size: 2em;
        margin: 0px;
        padding: 12px;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    #prev-page-btn {
        padding-right: 5px;
    }
    
    #next-page-btn {
        padding-left: 5px;
    }
}

@media screen and (max-width: 768px) {

    .page-count {
        font-size: 2em;
        margin-top: 15px
    }

    #prev-page-btn, #next-page-btn {
        font-size: 3em;
        margin: 5px;
    } 

    .page-count span {
        font-size: 0.8em;
    }
}

#prev-page-btn:hover,#next-page-btn:hover {
    opacity: 0.4;
}

#prev-page-btn {
    padding-right: 40px;
}

#next-page-btn {
    padding-left: 40px;
}

.image-view-container {
    background-color: rgba(0,0,0,1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
}

.image-container {
    position: relative;
    display: flex;
    background-color: black;
    width: 100vw;
    height: 95vh;
    margin-left: 0vw;
    margin-top: 0vh;   
    justify-content: center;      
}

.image-container img {  
    -webkit-user-select: none;  
        -ms-user-select: none;  
            user-select: none;
    width: 100%;
    height: 100%; 
    object-fit: contain;
}

.prev-slide-btn {
    position:absolute;
    top: 50%;
    left: 5%;
}

.next-slide-btn {
    position:absolute;
    top: 50%;
    right: 5%;
}

.slide-caption {
    color: rgb(255, 249, 160);
    text-align: left;
    width: 100%;
    font-size: 1em;
}

.close-btn {
    color: rgb(255, 255, 255);
    font-size: 3em;
    position: fixed;
    top: 20px;
    right: 5%;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.close-btn:hover {
    transition: opacity .15s ease-in-out;
    opacity: 0.6;
}

.prev-slide-btn, .next-slide-btn {
    color: white;
    font-size: 5em;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
} 

.prev-slide-btn:hover, .next-slide-btn:hover {
    transition: opacity .15s ease-in-out;
    opacity: 0.6;
}

@media screen and (max-width: 1024px) {

    .image-container {
        height: 90vh;
    }

    .prev-slide-btn, .next-slide-btn {     
        display: none;
    }

    .close-btn {
        top: 20px;
        right: 30px;
    }
 
    .slide-caption{
        text-align: left;
        margin-left: 5px;
        font-size: 16px;
        width: 98%;
    }
}

@media screen and (max-width:800px) {
   
    .close-btn {
        font-size: 3em;
        top: 0;
        right: 25px;
    }
}

@media screen and (max-width: 640px) {
    .slide-caption {
        font-size: 0.8em;
    }
}


/* ******************************************************* */

nav {
    text-align: center;
    align-items: center;
    top: -50px;
    border: none;
    position: relative;
    font-size: 40px;
}

.nav-container {
    height: 80px;
    border: 1px solid lightgray;
    border-left: none;
    border-right: none;
}

.sticky {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);    
    opacity: 0.8;
    transition: top 0.5s;
    margin-top: 0px;
    height: 60px;
}

@media screen and (max-width:1024px) {
    nav {
    font-size: 30px;
    }
}

@media screen and (max-width:800px) {
    nav {
    font-size: 25px;
    }
}

@media screen and (max-width: 500px) {

    .sticky {

        height: 40px;
        padding-top: 10px;
    }

    nav {
        font-size: 20px;
    }
}

.nav-link {

    color: black;
    text-decoration: none;
}

nav li {
    color: black;
    opacity: 0.5;
    display: inline-block;
    margin: 0 1em;
    color: black;
    font-family: 'Delius', cursive;
}

nav li:hover {
    opacity: 1.0;
    cursor: pointer;
}



/* --------- Trip Logs List ----------------------------------------------------- */

.triplog-list {
    width: 100vw;
    margin: 0 auto;
    margin-top: 5%;
}

.triplog-list h3 {
    text-align: center;
    font-size: 36px;
}

.triplog-list li {
    cursor: pointer;
    list-style: none;
}

.triplog-list li:hover {
    opacity: 0.6;
}

.triplog-list ul {
    -webkit-padding-start:0px;
            padding-inline-start:0px;
}

@media screen and (max-width: 460px) {
    .triplog-list h3 {
        font-size: 16px;
        padding-bottom: 0px;
    }

    .triplog {
        line-height: normal;
    }
}

/*-------------- Social Media / Contact Icons ------------------------------------- */

.social-container img {

    max-width: 64px;
    max-height: 64px;
    padding-left: 20px;
    padding-right: 20px;
}

.social-container h4 {
    margin-bottom : 30px;
}

#email-icon {
    width: 48px;
    height: 48px;
}

.youtube-video-container {
    margin-top: 5%;
    margin-bottom: 5%;
}

@media screen and (max-width: 580px) {

    .youtube-video-container{
        position: relative;
        width: 100%;
        padding-bottom: 56.25%; 
        height: 0;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .youtube-video-container iframe{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


/* ------------------Resource Links ------------------------------------------------*/

h4 {
    cursor: default;
}

.resource-container {
    width: 100%;
    height: 200px;
    margin-top: 50px;
    background-color: rgb(118, 163, 118);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
}

.resource-links {
    height: 160px;
    text-align: left;
    margin: 0 auto;
    padding: 20px;
    display: table-column;
}

.resource-links ul {
    list-style: none;
}

.resource-links li {
    padding: 3px;
    padding-bottom: 10px;
}

.resource-links a {
    color: white;;
}

.resource-links a:hover {
    opacity: 0.6;
    cursor: pointer;
}

.resource-links h4 {
    cursor: default;
    text-align: center;
    color: rgb(41, 61, 41);
}

@media screen and (max-width: 1024px) {

    .resource-links {
        padding: 6px;
        height: 200px;
    }

    .resource-links h4 {
        font-size: 26px;
    }

    .resource-links li {
        font-size: 22px;
        margin-bottom: 14px;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {

    .resource-links {
        padding: 0px;
        margin: 0;
    }
    .resource-links h4 {
        font-size: 14px;
        
    }
    .resource-links li {
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.top-margin-2 {
    margin-top: 2%;
}

.top-margin-5 {
    margin-top: 5%;
}

.portage-sign {

    max-height: 800px;
    max-width: 1200px;
}

.image-center-60 {
    width: 60%;
    height: 60%;
    margin: 0 auto;
}

.image-center-40 {
    width: 40%;
    height: 40%;
    margin: 0 auto;
}

.image-bear {

    width: 40%;
    height: 40%;
    float: left;
    margin-right: 5%;
}

.bear-text {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    
    .image-center-40 {
        width: 100%;
    }
    .image-center-60 {
        width: 100%;
    }
}


.fadeSlideFrame {
    position: relative;
    width: 40%;
    height: 400px;
    margin-left: auto;
    margin-right: auto;    
}

.fadeSlide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    -webkit-animation: fadeInOut 24s infinite;
            animation: fadeInOut 24s infinite;
    
}

.fadeSlide:nth-child(2) {
    -webkit-animation-delay: 8s;
            animation-delay: 8s;
}

.fadeSlide:nth-child(3) {
    -webkit-animation-delay: 16s;
            animation-delay: 16s;
}

@-webkit-keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }    
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }    
}
